import { getAuth, onAuthStateChanged } from "@firebase/auth";
import axios from "axios";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Loading from "../components/Loader/Loading";
import OTPModalWithButton from "../components/OTPModalWithButton";
import RegistrationComponent from "../components/root/RegistrationComponent";
import { adminRoute, merchantRoute, UserType } from "../contants/Constants";
import { apiEndPoint, baseUrl } from "../contants/Endpoints";
import { signUp } from "../store/user/api";
import { noAuthAxios } from "../utils/axiosWrapperOpen";
import { isLoggedIn } from "../utils/isLoggedIn";
import { toastMessage } from "../utils/toast";

function SignIn({ userType }) {
  const dispatch = useDispatch();
  // const history = useHistory();
  let navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  const [otpCode, setOtoCode] = useState(null);

  useEffect(() => {
    if (loggedIn) {
      // navigate(merchantRoute.LANDING);
      if (userType == UserType.ADMIN) {
        navigate(adminRoute.ADMIN_LANDING);
      } else {
        navigate(merchantRoute.LANDING);
      }
    } else {
    }
  }, [loggedIn]);

  // useEffect(() => {
  //   if(loggedIn){
  //     if(userType == UserType.ADMIN){
  //       navigate(adminRoute.ADMIN_LANDING)
  //     }else{
  //       navigate(merchantRoute.LANDING)
  //     }
  //   }
  // }, [loggedIn])

  const signupData = useSelector((state) => state.signupReducer.data);
  //new
  const signInData = useSelector((state) => state.signinReducer.data);
  useEffect(() => {
    if (signInData) {
      console.log(signInData);
      if (isLoggedIn()) {
        if (userType === UserType.ADMIN) {
          navigate(adminRoute.ADMIN_LANDING);
        } else {
          if (signInData.tncRequired === true) {
            navigate("/terms-and-conditions");
          } else {
            navigate(merchantRoute.LANDING);
          }
        }
      }
    }
  }, [signInData]);

  // const [phoneNumber, setnumber] = useState("");
  const [phoneCode, setPhoneCode] = useState("+880");
  const [confirmed, setConfirmed] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setotp] = useState("");
  const [title, setTitle] = useState("Mr");
  const values = ["Mr.", "Mrs."];
  const [show, setshow] = useState(false);
  const [confirmationResule, setconfirmationResule] = useState();
  const [tncVersion, setTncVersion] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifier, setVerifier] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (signupData && signupData.length) {
      setshow("none");
    }
  }, [signupData]);
  var firebsaeUser;
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      firebsaeUser = user;
      const uid = user.uid;
    } else {
    }
  });
  const gotoSignup = () => {
    const convertedNumber = phone.replace(/^0/, "");
    const phoneObject = { phoneNumber: phoneCode + convertedNumber };
    axios
      .post(`${baseUrl}${apiEndPoint.CHECK_USER}`, phoneObject)
      .then((res) => {
        if (!res?.data?.dbUserExist) {
          confrimPhoneWithOtp(phoneObject);
        } else {
          dispatch(
            signUp(
              {
                firstName,
                lastName,
                title,
                email,
                password,
                rePassword,
                tncVersion: tncVersion,
                phoneCode,
                phoneNumber:
                  phone.length > 0 && phone[0] === "0"
                    ? phone.substring(1)
                    : phone,
                firebaseId: res.data.uid,
                requestId: "registration",
                userType: "MERCHANT",
              },
              () => {
                setLoading(false);
                setShowSuccess(true);
              },
              () => {
                setLoading(false);
              }
            )
          );
          //toastMessage("firebase user");
        }
      });
  };

  const confrimPhoneWithOtp = () => {
    if (phone === "" || phone.length < 9) return;
    setLoading(true);

    axios
      .get(
        `${baseUrl}${apiEndPoint.OTP_RESEND}${
          phoneCode + phone
        }?is-reset=false&requestId=1234`
      )
      .then((res) => {
        if (res.status === 200) {
          setOtoCode(res.data);
          setLoading(false);
          setIsOpen(true);
        }

        if (res.status !== 200) {
          toastMessage("Error! Please try again", "error");
        }
      })
      .catch((err) => {
        toastMessage("Please wait 5 minutes");
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log(otp, "otp");
    if (otp !== "") {
      ValidateOtp();
    }
  }, [otp]);

  const ValidateOtp = () => {
    if (otp === null || confirmationResule === null) return;
    setIsOpen(false);
    setLoading(true);

    const req = {
      otp: otp,
      requestId: "otpValid",
      userName: phoneCode + phone,
    };
    noAuthAxios
      .post(`${baseUrl}${apiEndPoint.CHECK_OTP_IS_VALID}`, req)
      .then((res) => {
        if (res.data.acknowledged) {
          dispatch(
            signUp(
              {
                firstName,
                lastName,
                title,
                email,
                password,
                rePassword,
                tncVersion: tncVersion,
                phoneCode,
                phoneNumber:
                  phone.length > 0 && phone[0] === "0"
                    ? phone.substring(1)
                    : phone,
                firebaseId: 0,
                requestId: "abcdefgh",
                userType: "MERCHANT",
              },
              () => {
                setLoading(false);
                setShowSuccess(true);
              },
              () => {
                setLoading(false);
              }
            )
          );
        } else {
          toastMessage("Invalid OTP! please try again");
        }
      });
  };

  return (
    <>
      <div>
        {loading && (
          <div className="fixed h-screen w-screen bg-[#fffbef91] z-50 bottom-0 flex justify-center items-center">
            <Loading />
          </div>
        )}
        <center>
          <div style={{ display: !show ? "block" : "none" }}>
            <OTPModalWithButton
              modalIsOpen={modalIsOpen}
              setIsOpen={setIsOpen}
              ValidateOtp={ValidateOtp}
              updateOTP={setotp}
              resendOtp={confrimPhoneWithOtp}
            />
            <RegistrationComponent
              action={gotoSignup}
              phone={phone}
              setPhone={setPhone}
              phoneCode={phoneCode}
              setPhoneCode={setPhoneCode}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              rePassword={rePassword}
              setRePassword={setRePassword}
              value={title}
              setValue={setTitle}
              values={values}
              setTncVersion={setTncVersion}
              showOtpModal={confrimPhoneWithOtp}
              confirmed={confirmed}
              showSuccess={showSuccess}
              setShowSuccess={setShowSuccess}
            />
            <br />
            <br />
            <div id="recaptcha-container"></div>
          </div>
        </center>
        {/* <ModalGlobal
                    header="Number is Confirmed!"
                    openDefault={modalTrigger}
                    navigatepass={merchantRoute.LANDING}
                /> */}
      </div>
    </>
  );
}

export default SignIn;
