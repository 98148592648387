import React, { useEffect, useState } from "react";
import { AIRTICKET_CONTROLLER_API, baseUrl } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
export const optionsTime = {
  timeZone: "Asia/Dhaka", // Bangladesh timezone
  weekday: "short", // Short format for weekday (e.g., Wed)
  year: "numeric",
  month: "short", // Short format for month (e.g., Nov)
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};
export default function OtpQuery() {
  const [otpQuery, setOtpQuery] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [totalAirport, setTotalAirport] = useState(0);

  const handleGetOtpQuery = () => {
    authAxios
      .get(
        `${baseUrl}${AIRTICKET_CONTROLLER_API.OTP_QUERY_CHECK}?query=${searchText}`
      )
      .then((res) => {
        setOtpQuery(res?.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    const delay = setTimeout(() => {
      handleGetOtpQuery();
    }, 1000); // Delay of 2000 milliseconds (2 seconds)

    return () => clearTimeout(delay);
  }, [searchText]);

  return (
    <div className="mt-[60px]">
      <div className="max-w-[540px] flex justify-between items-center gap-[20px] h-full">
        <div className="w-full">
          <p className="text-[18px]">Search </p>
          <input
            placeholder="Search"
            className="w-full px-[12px] border border-btn-border rounded-[12px] py-[4px]"
            type="text"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      <table className="fl-table mt-[20px]">
        <thead className="sticky top-[88px]">
          <tr>
            <th>
              <span>SL</span>
            </th>
            <th>Time</th>
            <th>Number</th>
            <th>Active</th>
            <th>OTP</th>
          </tr>
        </thead>
        <tbody>
          {otpQuery?.otps?.map((otps, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td className={``}>
                {new Date(otps?.expiry_time).toLocaleString(
                  "en-US",
                  optionsTime
                )}
              </td>
              <td className={``}>{otps?.user_name}</td>
              <td className={``}>{otps?.active ? "True" : "False"}</td>
              <td className={``}>{otps?.otp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
