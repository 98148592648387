import React, { useEffect, useRef, useState } from "react";

const statusOptions = [
  "Unknown",
  "Confirmed",
  "OnHold",
  "Pending",
  "InProgress",
  "Cancelled",
  "UnConfirmed",
  "Expired",
  "Init",
  "Failed",
];

const StatusSelect = ({ selectedStatus, setSelectedStatus }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  // Handle outside click to close dropdown
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (status) => {
    setSelectedStatus(status);
    setIsOpen(false);
  };

  return (
    <div
      style={{ position: "relative", width: "400px", margin: "20px auto" }}
      ref={dropdownRef}
    >
      {/* Dropdown trigger */}
      <div
        onClick={toggleDropdown}
        style={{
          padding: "10px 15px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          cursor: "pointer",
          background: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "16px",
        }}
        className="whitespace-nowrap"
      >
        {selectedStatus || "Select a Status"}
        <span style={{ fontSize: "12px", marginLeft: "10px" }}>
          {isOpen ? "▲" : "▼"}
        </span>
      </div>

      {/* Dropdown menu */}
      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "45px",
            left: "0",
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: "5px",
            background: "#fff",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          {statusOptions.map((status) => (
            <div
              key={status}
              onClick={() => handleSelect(status)}
              style={{
                padding: "10px 15px",
                cursor: "pointer",
                fontSize: "16px",
                borderBottom: "1px solid #f0f0f0",
                transition: "background 0.2s",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.background = "#f9f9f9")
              }
              onMouseLeave={(e) => (e.currentTarget.style.background = "#fff")}
            >
              {status}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StatusSelect;
